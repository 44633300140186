import { SvgIcon } from '@mui/material';

export function BuilderIcon() {
  return (
    <SvgIcon
      sx={{
        width: 25,
        height: 25
      }}
    >
      <svg width='25' height='25' viewBox='0 0 25 25' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3538_18032)'>
          <mask
            id='mask0_3538_18032'
            style={{ maskType: 'luminance' }}
            maskUnits='userSpaceOnUse'
            x='0'
            y='2'
            width='25'
            height='20'
          >
            <path d='M0.25 2.26501H24.25V21.753H0.25V2.26501Z' fill='currentColor' />
          </mask>
          <g mask='url(#mask0_3538_18032)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M22.3227 21.735H2.1777C1.10945 21.735 0.251953 20.8775 0.251953 19.809V4.19101C0.251953 3.12251 1.10945 2.26501 2.1777 2.26501H22.3227C23.377 2.26501 24.2485 3.12251 24.2485 4.19101V19.809C24.2485 20.8775 23.377 21.735 22.3227 21.735ZM3.07745 5.02026C2.6557 5.02026 1.7842 5.17501 1.7842 4.51426C1.7842 3.85351 2.6557 4.00826 3.07745 4.00826C3.7382 4.00826 3.7382 5.02026 3.07745 5.02026ZM7.4777 4.00826C8.1382 4.00826 8.1382 5.02026 7.4777 5.02026C7.05595 5.02026 6.1702 5.17501 6.1702 4.51426C6.1702 3.85351 7.05595 4.00826 7.4777 4.00826ZM5.27045 4.00826C5.9452 4.00826 5.9452 5.02026 5.27045 5.02026C4.8487 5.02026 3.9772 5.17501 3.9772 4.51426C3.9772 3.85351 4.8487 4.00826 5.27045 4.00826ZM23.2365 6.53851H1.26395V19.809C1.26395 20.3153 1.6717 20.7228 2.1777 20.7228H22.3227C22.8147 20.7228 23.2365 20.3153 23.2365 19.809V6.53851Z'
              fill='currentColor'
            />
          </g>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.41301 13.7782L8.78501 18.1502C9.26301 18.6422 8.53201 19.3592 8.05401 18.8812L3.31651 14.1437C3.11976 13.9469 3.11976 13.6094 3.31651 13.4127L8.05401 8.6752C8.53201 8.19745 9.26301 8.92845 8.78501 9.4062L4.41301 13.7782ZM10.3313 19.0922C9.96576 19.0922 9.71276 18.7267 9.85351 18.3892L13.6913 8.7877C13.9443 8.1552 14.9003 8.5347 14.647 9.1672L10.8093 18.7827C10.725 18.9654 10.5423 19.0922 10.3313 19.0922ZM20.0875 13.7782L15.7155 9.4062C15.2375 8.92845 15.9545 8.19745 16.4465 8.6752L21.184 13.4127C21.3808 13.6094 21.3808 13.9469 21.184 14.1437L16.4465 18.8812C15.9685 19.3592 15.2235 18.6422 15.7155 18.1502L20.0875 13.7782Z'
            fill='currentColor'
          />
        </g>
        <defs>
          <clipPath id='clip0_3538_18032'>
            <rect width='24' height='24' fill='white' transform='translate(0.25)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
